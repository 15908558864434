<template>
  <div>
    <h1>Contact Page</h1>
  </div>
</template>
<script>

export default {

  created() {
      
    window.location.href = this.$store.state.origin+"admin/home";
  },

};
</script>